export const getMissingDataSources = async (date) => {
	const url = new URL(
		"api/report/missing-data-sources/",
		window.location.origin,
	);
	url.searchParams.append("date", date);
	const response = await fetch(url, {
		headers: {
			"X-Requested-With": "XMLHttpRequest",
		},
	});
	return await response.text();
};

export const getMetrics = async (signal) => {
	const url = new URL("/api/metrics", window.location.origin);
	const response = await fetch(url, {
		headers: {
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	return await response.json();
};

export const getDataSourceMissingDays = async (
	id,
	year,
	month,
	signal = null,
) => {
	const url = new URL("api/data-source/missing-days/", window.location.origin);
	url.searchParams.append("id", id);
	url.searchParams.append("year", year);
	url.searchParams.append("month", month);
	const response = await fetch(url, {
		headers: {
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	return await response.json();
};

export const getDataSourceStatus = async (id, signal = null) => {
	const url = new URL("/api/data-source/status/", window.location.origin);
	url.searchParams.append("id", id);
	const response = await fetch(url, {
		headers: {
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	return await response.json();
};

export const getSourcesStatus = async (signal) => {
	const url = new URL("/api/sources-status", window.location.origin);
	const response = await fetch(url, {
		headers: {
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	return await response.text();
};

export const getUserReportExport = async (id, type, signal) => {
	const url = new URL(
		`/api/user/report/${id}/export/${type}`,
		window.location.origin,
	);
	const response = await fetch(url, {
		headers: {
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	const filename = response.headers
		.get("Content-Disposition")
		.split("filename=")[1];
	response.blob().then((blob) => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = filename;
		a.click();
	});
};

export const getUserReportModal = async (id = null, signal = null) => {
	const url = new URL("/api/user/report/modal", window.location.origin);
	if (id) {
		url.searchParams.append("id", id);
	}
	const response = await fetch(url, {
		headers: {
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	return await response.text();
};

export const getUserReportResult = async (
	id,
	signal = null,
	options = null,
) => {
	const url = new URL(`/api/user/report/${id}/execute`, window.location.origin);
	if (options) {
		for (const option in options) {
			if (options[option] === null) {
				continue;
			}
			url.searchParams.append(option, options[option]);
		}
	}

	const headers = new Headers();
	headers.append("X-Requested-With", "XMLHttpRequest");
	if (options?.to_chart) {
		headers.append("Cache-Control", "no-cache");
	}

	return await fetch(url, {
		headers,
		signal,
	}).then((response) => {
		return response.json();
	});
};

export const getWebsiteDetails = async (
	id,
	isGlobal = false,
	signal = null,
	options = null,
) => {
	const endpoint = isGlobal
		? "api/website/global-details"
		: `api/website/${id}/details`;
	const url = new URL(endpoint, window.location.origin);
	if (options) {
		for (const option in options) {
			if (options[option] === null) {
				continue;
			}
			url.searchParams.append(option, options[option]);
		}
	}
	const response = await fetch(url, {
		headers: {
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	return await response.json();
};

export const getWebsiteDetailsExport = async (
	id,
	isGlobal = false,
	signal = null,
	options = null,
) => {
	const endpoint = isGlobal
		? "api/website/global-details"
		: `/api/website/${id}/details`;
	const url = new URL(endpoint, window.location.origin);
	const defaultOptions = {
		export: "csv",
	};
	for (const option in { ...defaultOptions, ...options }) {
		if (options[option] === null) {
			continue;
		}
		url.searchParams.append(option, options[option]);
	}
	const response = await fetch(url, {
		headers: {
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	const filename = response.headers
		.get("Content-Disposition")
		.split("filename=")[1];
	response.blob().then((blob) => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = filename;
		a.click();
	});
};

export const completeJob = async (id, csrfToken, signal = null) => {
	const url = new URL(
		`/api/scheduled_job/${id}/complete/`,
		window.location.origin,
	);
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"X-CSRFToken": csrfToken,
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	return await response.json();
};

export const cloneUserReport = async (id, csrfToken, signal = null) => {
	const url = new URL(`/api/user/report/${id}/clone`, window.location.origin);
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"X-CSRFToken": csrfToken,
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	return await response.json();
};

export const createOrUpdateUserReport = async (
	data,
	id = null,
	signal = null,
) => {
	if (id) {
		data.id = id;
	}
	const method = id ? "PUT" : "POST";
	const url = new URL("/api/user/report/modal", window.location.origin);
	const response = await fetch(url, {
		method: method,
		headers: {
			"Content-Type": "application/json",
			"X-CSRFToken": data.csrfmiddlewaretoken,
			"X-Requested-With": "XMLHttpRequest",
		},
		body: JSON.stringify(data),
		signal,
	});
	return await response.json();
};

export const deleteUserReport = async (id, csrfToken, signal = null) => {
	const url = new URL("/api/user/report/modal", window.location.origin);
	url.searchParams.append("id", id);
	const response = await fetch(url, {
		method: "DELETE",
		headers: {
			"X-CSRFToken": csrfToken,
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	return await response.json();
};

export const reworkDataSource = async (
	id,
	payload,
	csrfToken,
	signal = null,
) => {
	const url = new URL(`/api/data-source/${id}/rework/`, window.location.origin);
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"X-CSRFToken": csrfToken,
			"X-Requested-With": "XMLHttpRequest",
		},
		body: payload,
		signal,
	});
	return await response.json();
};

export const getAdPositionAnalytics = async (signal = null, options = null) => {
	const url = new URL(
		"/api/positions/performance-analytics",
		window.location.origin,
	);
	if (options) {
		for (const option in options) {
			if (options[option] === null) {
				continue;
			}
			if (Array.isArray(options[option])) {
				for (const value of options[option]) {
					url.searchParams.append(option, value);
				}
			} else {
				url.searchParams.append(option, options[option]);
			}
		}
	}
	const response = await fetch(url, {
		headers: {
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	return await response.json();
};

export const getAdPositionAnalyticsExport = async (
	signal = null,
	options = null,
) => {
	const url = new URL(
		"/api/positions/performance-analytics",
		window.location.origin,
	);
	const finalOptions = {
		...{
			export: "csv",
		},
		...options,
	};
	if (finalOptions) {
		for (const option in finalOptions) {
			if (finalOptions[option] === null) {
				continue;
			}
			if (Array.isArray(finalOptions[option])) {
				for (const value of finalOptions[option]) {
					url.searchParams.append(option, value);
				}
			} else {
				url.searchParams.append(option, finalOptions[option]);
			}
		}
	}
	const response = await fetch(url, {
		headers: {
			"X-Requested-With": "XMLHttpRequest",
		},
		signal,
	});
	const filename = response.headers
		.get("Content-Disposition")
		.split("filename=")[1];
	response.blob().then((blob) => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = filename;
		a.click();
	});
};
